import React, { Component } from 'react'
import { withStyles, } from '@mui/styles'
import { Typography, Grid, Box, Button } from '@mui/material'

//full file added by dev-trupti 11-11-2024
import logo from '../../ui-assets/appDownloadsLogo.png'
import backendHosts from '../../ui-utils/apiConfig'
import googlePlayStore from '../../ui-assets/googlePlayStore.png'
import appleStore from '../../ui-assets/appleStore.png'
//dev-trupti on 21-11-2024
import { httpRequest } from '../../ui-utils/api' 
import loadergif from '../../ui-assets/app_downloads_loader.gif' //dev-trupti on 26-11-2024

const styles = theme => ({
    mainlayout: {
        marginTop:'0px',  
    },
    mainGrid: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        // top:'10%'
        // height: '100vh',  
    },
    mainLogo: {
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',      
        backgroundPosition: 'center',   
        backgroundRepeat: 'no-repeat',
        height:'145px', 
        width:'145px',
        position: 'absolute',             
        top: '28%',                    
        left: '50%',               
        transform: 'translate(-50%, -50%)',
        zIndex:1
    },
    mainBox: {
        marginTop:'200px',
        height: '420px',
        width: "400px !important",
        borderRadius: '11px',
        backgroundColor:'#ffffff',
        position: 'relative',
        "@media only screen and (max-width: 500px)": {
            width: '80% !important',
        },
    },
    boxContent: {
        marginTop:'53px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center !important'

    },
    txtfeild:{
        height:'38px !important'
    },
    Refbox:{
        width:'228px !important',
        borderRadius:'20px !important', 
        backgroundColor:'#F6FFD8 !important',
        borderStyle:'dashed !important', 
        borderColor:'#F3D45E !important', 
        borderWidth:'1px !important',
        display:'flex !important',
    },
    webText : {
        marginBottom:'3% !important',
        fontSize:'14px',
        color:'#0092F3',
        fontFamily: 'cardiuma-Bold !important',
    },
    codeText:{
        width:'100% !important',
        display:'flex !important',
        justifyContent:'center !important',
        alignItems:'center !important',
        textAlign:'center !important'
    },
    copyButton: {
        width:'68px !important',
        backgroundColor:'#F3D45E !important',
        borderRadius:'19px !important',
        padding:'5px 14px 5px 14px !important',
        fontFamily: 'cardiuma-SemiBold !important',
        textTransform:'none !important',
        margin: '1px 1px 1px 0px !important'
    },
    copytext:{
        marginTop:'2.5% !important',
        fontSize: '12px !important',
        fontFamily: 'cardiuma-regular !important',
        color:'#979797 !important'
    },
    refcode:{
        marginTop:'5px !important',
        fontSize: '14px !important',
        fontFamily: 'cardiuma-regular !important',
    },
    amountText:{
        marginTop:'5% !important'
    },
    strikeAmount:{
        textDecoration:'line-through !important',
        fontSize:'12px !important',
        color:'#999999 !important',
        fontFamily: 'cardiuma-regular !important',
    },
    newAmount: {
        textDecoration:'none !important',
        fontSize:'24px !important',
        color:'#A8CD27 !important',
        fontFamily: 'cardiuma-SemiBold !important',
    },
    freeacc:{
        background: 'linear-gradient(90deg, #4E35C9 0%, #A328EA 54.5%, #261A63 100%) !important',
        backgroundClip: 'text !important',
        WebkitBackgroundClip: 'text !important',
        color: 'transparent !important',
        fontSize:'18px !important',
        fontFamily: 'cardiuma-Bold !important',
    },
    expertText :{
        padding:'0px 20px 0px 20px !important',
        fontSize:'12px !important',
        fontFamily: 'cardiuma-SemiBold !important',
        color:'#82858B !important',
    },
    google:{
        marginTop:'5% !important',
    },
    apple:{
        marginTop:'2.5% !important',
        marginBottom: '6% !important'
    },
    store:{
        height:'50px !important',
        width: '172px !important',
    },
    //dev-trupti on 21-11-2024
    normalTxt:{
        fontSize:'14px !important',
        fontFamily: 'cardiuma-regular !important',
        color: '#000000 !important',
        margin:'0px 20px 0px 20px !important', //dev-trupti on 05-12-2024
        textAlign:'center !important'
    }
})

class AppDowmloads extends Component {
    constructor(props) {
        super(props)

        this.state = {
          copytext:'Copy',
          code:'',
          show:false,
          load:true, //dev-trupti on 26-11-2024,
          subscriptionAmt : 0, //dev-trupti on 05-12-2024
          expertDays: 0
        }
    }

    TextCopy()
    {   
        navigator.clipboard.writeText(this.state.code);
        this.setState({copytext:'Copied'})
    }
    
    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F3D45E"

        //dev-trupti on 05-12-2024
        const { location } = this.props;
        const queryParams = new URLSearchParams(location.search);
        const refcode = queryParams.get('refcode');
        
        if(refcode !== null){
            try{
                const payload = {
                    "type":"REFCODE",
                    refcode
                }
                console.log(payload)//
                const app_Download_Data = await httpRequest({
                    endPoint: `/api/campaign/app_downloads`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                if(app_Download_Data.status){
                    this.setState({show:app_Download_Data.status, code:app_Download_Data.code,
                        subscriptionAmt:app_Download_Data.subscriptionAmt,
                        expertDays:app_Download_Data.expertRecommendationDays
                    })
                }else{
                    this.setState({show:app_Download_Data.status})
                }
                this.setState({load:false}) 
            }catch(err){
                console.log(err)
            }
        }else{
            //dev-trupti on 21-11-2024
            try {
                const payload = {
                    "type":"NO_REFCODE"
                }
                const app_Download_Data = await httpRequest({
                    endPoint: `/api/campaign/app_downloads`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                if(app_Download_Data.status){
                    this.setState({show:app_Download_Data.status, code:app_Download_Data.code, 
                        subscriptionAmt:app_Download_Data.subscriptionAmt, 
                        expertDays:app_Download_Data.expertRecommendationDays
                    })
                }else{
                    this.setState({show:app_Download_Data.status})
                }
                this.setState({load:false}) //dev-trupti on 26-11-2024
            } catch (error) {
                console.log(error)
            }
        }
    }

    render() {
        const { classes } = this.props;
        const {show, code ,copytext, load, subscriptionAmt, expertDays} = this.state //dev-trupti on 05-12-2024
        return (
            <div className={classes.mainlayout}>
               <Box className={classes.mainGrid}>
                    <Box className={classes.mainLogo}>
                    </Box>
                    <Grid className={classes.mainBox}>       
                        <Box className={classes.boxContent}>
                            <Typography className={classes.webText} 
                            onClick={()=>{window.open(`${backendHosts.WEBSITE}`)}}>
                                    https://bullforce.co
                            </Typography>
                            {/* dev-trupti on 26-11-2024 */}
                            {
                                load === true ? <>
                                    <Grid>
                                        <img 
                                        src={loadergif} style={{marginTop:'30px', marginBottom:'30px',height:'100px', width:'100px'}}
                                        ></img>
                                    </Grid>                                 
                                </>:<>
                                    {/* dev-trupti on 05-12-2024 */}
                                    {!show && <Typography sx={{marginBottom : subscriptionAmt !="0"? '5px !important' : '30px !important', marginTop: subscriptionAmt !="0"? '20px !important' : '30px !important' }}  className={classes.normalTxt}>Bullforce: The new-age investing platform built to simplify and empower your financial journey.</Typography>}
                                    {show && <><div className={classes.txtfeild}>
                                        <Box className={classes.Refbox}>
                                            <div className={classes.codeText}><Typography className={classes.refcode}>{code}</Typography></div>
                                            <Button className={classes.copyButton}  onClick={() => this.TextCopy()}>
                                                    <span style={{ color: 'black' , marginTop:'2px'}}>{copytext}</span> 
                                            </Button>
                                        </Box>
                                    </div>
                                    {/* dev-trupti on 05-12-2024 */}
                                    <Typography className={classes.copytext}>Copy & paste this code {subscriptionAmt =="0" && "for free account"}</Typography>

                                    {(!show || subscriptionAmt !="0")? <Typography sx={{marginBottom : subscriptionAmt !="0"? '5px !important' : '30px !important', marginTop: subscriptionAmt !="0"? '20px !important' : '30px !important' }} className={classes.normalTxt}>Bullforce: The new-age investing platform built to simplify and empower your financial journey.</Typography>: null}

                                    {subscriptionAmt =="0"  && <Grid ><Typography  className={classes.amountText}><span className={classes.strikeAmount}>₹499</span> <span className={classes.newAmount}>₹00</span></Typography>
                                    <Typography className={classes.freeacc}>Free Account</Typography></Grid>}
                                    {expertDays >"0" && expertDays !=null  ? <Typography sx={{marginTop: subscriptionAmt=="0" ? "0px": "0px"}} className={classes.expertText}>
                                    {/* dev-trupti on 09-12-2024 */}
                                        {
                                        expertDays > "0" && expertDays != null ? (
                                            <Typography
                                            sx={{ marginTop: subscriptionAmt == "0" ? "0px" : "0px" }}
                                            className={classes.expertText}
                                            >
                                            {
                                                // Case when expertDays is less than 30
                                                expertDays < 30
                                                ? `+${expertDays} day${expertDays > 1 ? 's' : ''} free access to our Expert Recommendations`

                                                // Case when expertDays is 30 or more
                                                : `+ ${Math.floor(expertDays / 30)} month${Math.floor(expertDays / 30) > 1 ? 's' : ''}${expertDays % 30 > 0 ? ` & ${expertDays % 30} day${expertDays % 30 > 1 ? 's' : ''}` : ''} free access to our Expert Recommendations`
                                            }
                                            </Typography>
                                        ) : null
                                        }
                                    </Typography>: null}
                                    </>}
                                </>
                            } 
                            <Box className={classes.google} onClick={()=> window.open('https://play.google.com/store/apps/details?id=com.bullforce.xts')}><img src={googlePlayStore} className={classes.store}></img></Box>
                            <Box className={classes.apple} onClick={()=> window.open('https://apps.apple.com/in/app/bullforce/id6473841422')}><img src={appleStore} className={classes.store}></img></Box>
                        </Box>
                    </Grid>
               </Box>
            </div>
        )
    }
}
export default (withStyles(styles)((AppDowmloads)));
